import { Controller } from "@hotwired/stimulus"

const LOCALE_OPTIONS = {
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
}
const NFC_UID_MIN_LENGTH = 0

export default class extends Controller {
  static targets = [
    "resellForm",
    "formReady",
    "inputState",
    "confirmState",
    // Input State
    "nfcUidInput",
    "priceInput",
    "priceHelperText",
    "imageInput",
    "changeImageBtn",
    "netPriceText",
    "breakdownSellPrice",
    "breakdownFee",
    "breakdownNetPrice",
    "nextStateButton",
    "wristbandRulesContainer",
    "wristbandRulesCheckbox",
    // Confirm State
    "termsContainer",
    "termsCheckbox",
    "submitFormButton",
  ]

  /**
   * This values parsing from controller @variables
   * orders - @eventpop_seller_orders
   * resellEvent - @resell_event
   * newTicket - @ticket
   */
  static values = {
    userLocale: String,
    resellEvent: Object,
    newTicket: Object,
    platformFeePercentage: Number,
  }

  // Class Properties
  isAcceptableNFCUID = false
  resellPriceDecimal = 0.0
  eventMinimumListPriceDecimal = 0.0
  eventMaximumListPriceDecimal = 0.0
  netPriceDecimal = 0.0
  feeDecimal = 0.0

  // Lifecycle
  connect() {
    this.resellPriceDecimal = parseFloat(String(this.newTicketValue.price))
    this.feeDecimal = parseFloat(
      String(this.platformFeePercentageValue / 100)
    )

    this.eventMinimumListPriceDecimal = parseFloat(
      String(this.resellEventValue.minimum_list_price_satangs / 100)
    )
    this.eventMaximumListPriceDecimal = parseFloat(
      String(this.resellEventValue.maximum_list_price_satangs / 100)
    )

    this.updateSummarySection()
  }

  // Dataflow Functions
  updateSummarySection() {
    this.netPriceDecimal =
      this.resellPriceDecimal - this.resellPriceDecimal * this.feeDecimal

    this.breakdownSellPriceTarget.textContent =
      this.resellPriceDecimal.toLocaleString(this.userLocaleValue, LOCALE_OPTIONS)

    this.breakdownFeeTarget.textContent = (
      this.resellPriceDecimal * this.feeDecimal
    ).toLocaleString(this.userLocaleValue, LOCALE_OPTIONS)

    this.breakdownNetPriceTarget.textContent =
      this.netPriceDecimal.toLocaleString(this.userLocaleValue, LOCALE_OPTIONS)

    this.netPriceTextTargets.forEach(target => {
      return (target.textContent = this.netPriceDecimal.toLocaleString(
        this.userLocaleValue,
        LOCALE_OPTIONS
      ))
    })
  }

  checkFormValidity() {
    // Check form is valid
    if (
      this.isAcceptableNFCUID &&
      this.resellPriceDecimal >= this.getMinimumListPrice() &&
      this.resellPriceDecimal <= this.getMaximumListPrice() &&
      this.imageInputTargets.map(target => target.files.length > 0).every(Boolean) &&
      this.wristbandRulesCheckboxTarget.checked
    ) {
      this.nextStateButtonTarget.disabled = false
      this.nextStateButtonTarget.classList.remove("disabled")
      return true
    } else {
      this.nextStateButtonTarget.disabled = true
      this.nextStateButtonTarget.classList.add("disabled")
      return false
    }
  }

  // Action Functions
  handleNfcUidChange(event) {
    this.isAcceptableNFCUID = (event.target.value.length > NFC_UID_MIN_LENGTH)

    if (this.isAcceptableNFCUID) {
      this.nfcUidInputTarget.classList.remove("invalid")
    } else {
      this.nfcUidInputTarget.classList.add("invalid")
    }

    this.nfcUidInputTarget.value = event?.target?.value?.toUpperCase()
    this.checkFormValidity()
  }

  handlePriceChange(event){
    this.resellPriceDecimal = event.target.value

    if (event.target.value < 0) {
      this.priceInputTarget.value = this.getMinimumListPrice()
      this.resellPriceDecimal = this.getMinimumListPrice()
      this.updateSummarySection()
      return
    }

    // Guard prevents user type start with double 00
    if (event.target.value.startsWith('0') && event.target.value.length > 1) {
      this.priceInputTarget.value = 0
      this.resellPriceDecimal = 0
      this.updateSummarySection()
      return
    }

    if (event.target.value > this.getMaximumListPrice()) {
      this.priceInputTarget.value = this.getMaximumListPrice()
      this.resellPriceDecimal = this.getMaximumListPrice()
      this.updateSummarySection()
      return
    }

    if (
      event.target.value < this.getMinimumListPrice() ||
      event.target.value > this.getMaximumListPrice() ||
      isNaN(event.target.value)
    ) {
      this.priceInputTarget.classList.add("invalid")
      this.priceHelperTextTarget.classList.remove("hidden")
    } else {
      this.priceInputTarget.classList.remove("invalid")
      this.priceHelperTextTarget.classList.add("hidden")
    }

    this.updateSummarySection()
    this.checkFormValidity()
  }

  handleFileChange() {
    this.checkFormValidity()
  }

  handleRulesCheckboxChange() {
    if (this.wristbandRulesCheckboxTarget.checked) {
      this.wristbandRulesContainerTarget.classList.replace("text-yellow-600", "text-green-600")
      this.wristbandRulesContainerTarget.classList.replace("bg-yellow-50", "bg-green-50")
      this.wristbandRulesContainerTarget.classList.replace("border-yellow-300", "border-green-300")
    } else {
      this.wristbandRulesContainerTarget.classList.replace("text-green-600", "text-yellow-600")
      this.wristbandRulesContainerTarget.classList.replace("bg-green-50", "bg-yellow-50")
      this.wristbandRulesContainerTarget.classList.replace("border-green-300", "border-yellow-300")
    }

    this.checkFormValidity()
  }

  handleTermsCheckboxChange() {
    this.submitFormButtonTarget.disabled = !this.termsCheckboxTarget.checked

    if (this.termsCheckboxTarget.checked) {
      this.submitFormButtonTarget.classList.remove("disabled")
    } else {
      this.submitFormButtonTarget.classList.add("disabled")
    }
  }

  // Handling State Functions
  enableInputs(mode) {
    const INPUT_TARGET = [this.nfcUidInputTarget, this.priceInputTarget]

    INPUT_TARGET.forEach(target => {
      target.disabled = mode === "add"
      target.classList[mode]("readonly")
    })

    // For Image Inputs
    this.imageInputTargets.forEach(target => {
      target.disabled = mode === "add"

      if (
        target.previousElementSibling &&
        target.previousElementSibling.hasAttribute("data-image-preview-target") &&
        target.previousElementSibling.getAttribute("data-image-preview-target") === "previewReady"
      ) {
        target.previousElementSibling?.classList[mode]("cursor-not-allowed", "hover:grayscale")
      }
    })

    this.changeImageBtnTargets.forEach(target => {
      target.disabled = mode === "add"
      target.classList[mode]("hidden")
    })
  }

  goToConfirmState() {
    if (!this.checkFormValidity()) return

    this.inputStateTarget.classList.replace("flex", "hidden")
    this.confirmStateTarget.classList.replace("hidden", "flex")
    this.enableInputs("add")
    this.wristbandRulesCheckboxTarget.disabled = true
    this.wristbandRulesCheckboxTarget.classList.add("text-stone-600")
    this.termsContainerTarget.scrollIntoView({ behavior: "smooth" })
  }

  goBackInputState() {
    this.confirmStateTarget.classList.replace("flex", "hidden")
    this.inputStateTarget.classList.replace("hidden", "flex")
    this.wristbandRulesCheckboxTarget.disabled = false
    this.wristbandRulesCheckboxTarget.classList.remove("text-stone-600")
    this.enableInputs("remove")
  }

  submitForm() {
    // Enable inputs before submit
    this.enableInputs("remove")
    this.priceInputTarget.value = this.resellPriceDecimal
    this.resellFormTarget.submit()
  }

  getMinimumListPrice() {
    return this.eventMinimumListPriceDecimal
  }

  getMaximumListPrice() {
    return this.eventMaximumListPriceDecimal
  }
}
