{
  "en": {
    "action": {
      "cancel": "Cancel",
      "change": "Change",
      "update": "Update",
      "upload": "Upload",
      "uploading": "Uploading"
    },
    "activerecord": {
      "attributes": {
        "bank_account": {
          "account_type": {
            "default": "Thai bank account",
            "wise": "International bank account"
          },
          "status": {
            "fallback": "Not setup yet",
            "pending": "Pending",
            "rejected": "Rejected",
            "title": "Status",
            "verified": "Success"
          }
        },
        "order": {
          "completed_at": "Order Completed At",
          "created_at": "Ordered At",
          "payment_method": {
            "ali_pay": "Ali Pay",
            "credit_card": "Credit Card",
            "mobile_banking_kbank": "KBank (Mobile Banking)",
            "title": "Payment Method",
            "unknown_payment": "---"
          },
          "status": {
            "canceled": "Canceled",
            "completed": "Completed",
            "pending": "Pending",
            "title": "Order status"
          }
        },
        "ticket": {
          "delivery_courier": "Shipment Courier",
          "delivery_proof_image": "Shipment Proof Photo",
          "delivery_tracking_code": "Tracking Number",
          "eventpop_order_id": "Order Number",
          "eventpop_ticket_id": "Ticket to sell",
          "nfc_images": "Wristband images",
          "nfc_uid": "Wristband ID",
          "payout_status": {
            "paid": "paid",
            "pending": "Pending",
            "title": "Payout Status"
          },
          "price": "Price",
          "reject_note": "Reject Note",
          "reject_reason": {
            "other": "Other",
            "photo_requirements_not_met": "Photo requirements not met",
            "title": "Reject Reason",
            "unclear_wristband_photos": "Unclear wristband photos",
            "wristband_id_and_photo_mismatch": "Wristband ID and photo mismatch"
          },
          "status": {
            "listed": "Listed",
            "pending": "In Verification",
            "pending_wristband": "Wristband to Deliver",
            "rejected": "Rejected",
            "sold": "Sold",
            "title": "Ticket status",
            "unlisted": "Unlisted",
            "wait_wristband_deliver": "Wait Wristband Deliver"
          }
        }
      },
      "errors": {
        "bank_account": {
          "header": "Your bank account rejected by these reasons:"
        },
        "messages": {
          "record_invalid": "Validation failed: %{errors}",
          "restrict_dependent_destroy": {
            "has_many": "Cannot delete record because dependent %{record} exist",
            "has_one": "Cannot delete record because a dependent %{record} exists"
          }
        },
        "models": {
          "ticket": {
            "attributes": {
              "delivery_proof_image": {
                "blank": "Please upload a delivery proof image",
                "invalid_image_type": "Please upload a PNG, JPG or JPEG image"
              },
              "nfc_images": {
                "at_least_two": "Please upload front-side and back-side images of the wristband",
                "invalid_image_type": "Please upload a PNG, JPG or JPEG image"
              },
              "price": {
                "greater_than": "Price must start at: %{min}",
                "greater_than_or_equal_to": "Price must start at: %{min}",
                "less_than": "Price must not exceed: %{max}",
                "less_than_or_equal_to": "Price must not exceed: %{max}"
              }
            }
          }
        }
      },
      "models": {
        "bank_account": {
          "one": "1 bank account",
          "other": "%{count} bank accounts",
          "zero": "No bank account available"
        },
        "order": {
          "one": "1 order",
          "other": "%{count} orders",
          "zero": "No order available"
        },
        "ticket": {
          "one": "1 ticket",
          "other": "%{count} tickets",
          "zero": "No ticket available"
        }
      },
      "placeholders": {
        "ticket": {
          "eventpop_order_id": "Choose Order Number",
          "eventpop_ticket_id": "Choose Ticket",
          "nfc_uid": "Enter Wristband ID",
          "price": "Enter the price you want to sell the ticket"
        }
      }
    },
    "api_errors": {
      "omise": {
        "recipient": {
          "account_not_found": "Bank account not found",
          "bank_not_found": "Bank not found",
          "name_mismatch": "Bank account name mismatch"
        }
      },
      "wise": {
        "create_recipient": "Failed to verify payout account"
      }
    },
    "avo": {
      "action_ran_successfully": "Action ran successfully!",
      "actions": "Actions",
      "add_filter": "Add filter",
      "and_x_other_resources": "and %{count} other resources",
      "applied": "applied",
      "are_you_sure": "Are you sure?",
      "are_you_sure_detach_item": "Are you sure you want to detach this %{item}.",
      "are_you_sure_you_want_to_run_this_option": "Are you sure you want to run this action?",
      "attach": "Attach",
      "attach_and_attach_another": "Attach & Attach another",
      "attach_item": "Attach %{item}",
      "attachment_class_attached": "%{attachment_class} attached.",
      "attachment_class_detached": "%{attachment_class} detached.",
      "attachment_destroyed": "Attachment destroyed",
      "attachment_failed": "Failed to attach %{attachment_class}",
      "cancel": "Cancel",
      "choose_a_country": "Choose a country",
      "choose_an_option": "Choose an option",
      "choose_item": "Choose %{item}",
      "clear_value": "Clear value",
      "click_to_reveal_filters": "Click to reveal filters",
      "close": "Close",
      "close_modal": "Close modal",
      "confirm": "Confirm",
      "copy": "Copy",
      "create_new_item": "Create new %{item}",
      "dashboard": "Dashboard",
      "dashboards": "Dashboards",
      "default_scope": "All",
      "delete": "delete",
      "delete_file": "Delete file",
      "delete_item": "Delete %{item}",
      "detach_item": "detach %{item}",
      "details": "details",
      "download": "Download",
      "download_file": "Download file",
      "download_item": "Download %{item}",
      "edit": "edit",
      "edit_item": "edit %{item}",
      "empty_dashboard_message": "Add cards to this dashboard",
      "failed": "Failed",
      "failed_to_find_attachment": "Failed to find attachment",
      "failed_to_load": "Failed to load",
      "file": {
        "one": "file",
        "other": "files"
      },
      "filter_by": "Filter by",
      "filters": "Filters",
      "go_back": "Go back",
      "grid_view": "Grid view",
      "hide_content": "Hide content",
      "home": "Home",
      "key_value_field": {
        "add_row": "Add row",
        "delete_row": "Delete row",
        "key": "Key",
        "reorder_row": "Reorder row",
        "value": "Value"
      },
      "less_content": "Less content",
      "list_is_empty": "List is empty",
      "loading": "Loading",
      "media_library": {
        "title": "Media Library"
      },
      "more": "More",
      "more_content": "More content",
      "more_records_available": "There are more records available.",
      "new": "new",
      "next_page": "Next page",
      "no_cancel": "No, cancel",
      "no_cards_present": "No cards present",
      "no_item_found": "No record found",
      "no_options_available": "No options available",
      "no_related_item_found": "No related record found",
      "not_authorized": "You are not authorized to perform this action.",
      "number_of_items": {
        "one": "one %{item}",
        "other": "%{count} %{item}",
        "zero": "no %{item}"
      },
      "oops_nothing_found": "Oops! Nothing found...",
      "order": {
        "higher": "Move record higher",
        "lower": "Move record lower",
        "reorder_record": "Reorder record",
        "to_bottom": "Move record to bottom",
        "to_top": "Move record to top"
      },
      "per_page": "Per page",
      "prev_page": "Previous page",
      "records_selected_from_all_pages_html": "All records selected from all pages",
      "remove_selection": "Remove selection",
      "reset": "reset",
      "reset_filters": "Reset filters",
      "resource_created": "Record created",
      "resource_destroyed": "Record destroyed",
      "resource_updated": "Record updated",
      "resources": "Resources",
      "run": "Run",
      "save": "Save",
      "search": {
        "cancel_button": "Cancel",
        "placeholder": "Search"
      },
      "select_all": "Select all",
      "select_all_matching": "Select all matching",
      "select_item": "Select item",
      "show_content": "Show content",
      "sign_out": "Sign out",
      "sort_asc": "Sort ascending",
      "sort_desc": "Sort descending",
      "sort_reset": "Reset sorting",
      "switch_to_view": "Switch to %{view_type} view",
      "table_view": "Table view",
      "this_field_has_attachments_disabled": "This field has attachments disabled.",
      "tools": "Tools",
      "type_to_search": "Type to search.",
      "unauthorized": "Unauthorized",
      "undo": "undo",
      "view": "View",
      "view_item": "view %{item}",
      "visit_record_on_external_path": "Visit record on external path",
      "was_successfully_created": "was successfully created",
      "was_successfully_updated": "was successfully updated",
      "x_items_more": {
        "one": "one more item",
        "other": "%{count} more items",
        "zero": "no more items"
      },
      "x_records_selected_from_a_total_of_x_html": "<span class=\"font-bold text-gray-700\">%{selected}</span> records selected on this page from a total of <span class=\"font-bold text-gray-700\">%{count}</span>",
      "x_records_selected_from_all_pages_html": "<span class=\"font-bold text-gray-700\">%{count}</span> records selected from all pages",
      "x_records_selected_from_page_html": "<span class=\"font-bold text-gray-700\">%{selected}</span> records selected on this page",
      "yes_confirm": "Yes, I'm sure",
      "you_cant_upload_new_resource": "You can't upload files into the Trix editor until you save the resource.",
      "you_havent_set_attachment_key": "You haven't set an `attachment_key` to this Trix field.",
      "you_missed_something_check_form": "You might have missed something. Please check the form."
    },
    "common": {
      "and": "and",
      "or": "or",
      "with": "with"
    },
    "date": {
      "abbr_day_names": [
        "Sun",
        "Mon",
        "Tue",
        "Wed",
        "Thu",
        "Fri",
        "Sat"
      ],
      "abbr_month_names": [
        null,
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
      ],
      "day_names": [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
      ],
      "formats": {
        "default": "%Y-%m-%d",
        "long": "%B %d, %Y",
        "short": "%b %d"
      },
      "month_names": [
        null,
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ],
      "order": [
        "year",
        "month",
        "day"
      ]
    },
    "datetime": {
      "distance_in_words": {
        "about_x_hours": {
          "one": "about 1 hour",
          "other": "about %{count} hours"
        },
        "about_x_months": {
          "one": "about 1 month",
          "other": "about %{count} months"
        },
        "about_x_years": {
          "one": "about 1 year",
          "other": "about %{count} years"
        },
        "almost_x_years": {
          "one": "almost 1 year",
          "other": "almost %{count} years"
        },
        "half_a_minute": "half a minute",
        "less_than_x_minutes": {
          "one": "less than a minute",
          "other": "less than %{count} minutes"
        },
        "less_than_x_seconds": {
          "one": "less than 1 second",
          "other": "less than %{count} seconds"
        },
        "over_x_years": {
          "one": "over 1 year",
          "other": "over %{count} years"
        },
        "x_days": {
          "one": "1 day",
          "other": "%{count} days"
        },
        "x_minutes": {
          "one": "1 minute",
          "other": "%{count} minutes"
        },
        "x_months": {
          "one": "1 month",
          "other": "%{count} months"
        },
        "x_seconds": {
          "one": "1 second",
          "other": "%{count} seconds"
        }
      },
      "prompts": {
        "day": "Day",
        "hour": "Hour",
        "minute": "Minute",
        "month": "Month",
        "second": "Seconds",
        "year": "Year"
      }
    },
    "devise": {
      "confirmations": {
        "confirmed": "Your email address has been successfully confirmed.",
        "send_instructions": "You will receive an email with instructions for how to confirm your email address in a few minutes.",
        "send_paranoid_instructions": "If your email address exists in our database, you will receive an email with instructions for how to confirm your email address in a few minutes."
      },
      "failure": {
        "already_authenticated": "You are already signed in.",
        "inactive": "Your account is not activated yet.",
        "invalid": "Invalid %{authentication_keys} or password.",
        "last_attempt": "You have one more attempt before your account is locked.",
        "locked": "Your account is locked.",
        "not_found_in_database": "Invalid %{authentication_keys} or password.",
        "timeout": "Your session expired. Please sign in again to continue.",
        "unauthenticated": "You need to sign in or sign up before continuing.",
        "unconfirmed": "You have to confirm your email address before continuing."
      },
      "mailer": {
        "confirmation_instructions": {
          "subject": "Confirmation instructions"
        },
        "email_changed": {
          "subject": "Email Changed"
        },
        "password_change": {
          "subject": "Password Changed"
        },
        "reset_password_instructions": {
          "subject": "Reset password instructions"
        },
        "unlock_instructions": {
          "subject": "Unlock instructions"
        }
      },
      "omniauth_callbacks": {
        "failure": "Could not authenticate you from %{kind} because \"%{reason}\".",
        "success": "Successfully authenticated from %{kind} account."
      },
      "passwords": {
        "no_token": "You can't access this page without coming from a password reset email. If you do come from a password reset email, please make sure you used the full URL provided.",
        "send_instructions": "You will receive an email with instructions on how to reset your password in a few minutes.",
        "send_paranoid_instructions": "If your email address exists in our database, you will receive a password recovery link at your email address in a few minutes.",
        "updated": "Your password has been changed successfully. You are now signed in.",
        "updated_not_active": "Your password has been changed successfully."
      },
      "registrations": {
        "destroyed": "Bye! Your account has been successfully canceled. We hope to see you again soon.",
        "signed_up": "Welcome! You have signed up successfully.",
        "signed_up_but_inactive": "You have signed up successfully. However, we could not sign you in because your account is not yet activated.",
        "signed_up_but_locked": "You have signed up successfully. However, we could not sign you in because your account is locked.",
        "signed_up_but_unconfirmed": "A message with a confirmation link has been sent to your email address. Please follow the link to activate your account.",
        "update_needs_confirmation": "You updated your account successfully, but we need to verify your new email address. Please check your email and follow the confirmation link to confirm your new email address.",
        "updated": "Your account has been updated successfully.",
        "updated_but_not_signed_in": "Your account has been updated successfully, but since your password was changed, you need to sign in again."
      },
      "sessions": {
        "already_signed_out": "Signed out successfully.",
        "signed_in": "Signed in successfully.",
        "signed_out": "Signed out successfully."
      },
      "unlocks": {
        "send_instructions": "You will receive an email with instructions for how to unlock your account in a few minutes.",
        "send_paranoid_instructions": "If your account exists, you will receive an email with instructions for how to unlock it in a few minutes.",
        "unlocked": "Your account has been unlocked successfully. Please sign in to continue."
      }
    },
    "errors": {
      "connection_refused": "Oops! Failed to connect to the Web Console middleware.\nPlease make sure a rails development server is running.\n",
      "format": "%{attribute} %{message}",
      "messages": {
        "accepted": "must be accepted",
        "already_confirmed": "was already confirmed, please try signing in",
        "blank": "can't be blank",
        "confirmation": "doesn't match %{attribute}",
        "confirmation_period_expired": "needs to be confirmed within %{period}, please request a new one",
        "empty": "can't be empty",
        "equal_to": "must be equal to %{count}",
        "even": "must be even",
        "exclusion": "is reserved",
        "expired": "has expired, please request a new one",
        "greater_than": "must be greater than %{count}",
        "greater_than_or_equal_to": "must be greater than or equal to %{count}",
        "in": "must be in %{count}",
        "inclusion": "is not included in the list",
        "invalid": "is invalid",
        "invalid_currency": "has invalid format (must be '100', '5%{decimal}24', or '123%{thousands}456%{decimal}78'). Got %{currency}",
        "less_than": "must be less than %{count}",
        "less_than_or_equal_to": "must be less than or equal to %{count}",
        "model_invalid": "Validation failed: %{errors}",
        "not_a_number": "is not a number",
        "not_an_integer": "must be an integer",
        "not_found": "not found",
        "not_locked": "was not locked",
        "not_saved": {
          "one": "1 error prohibited this %{resource} from being saved:",
          "other": "%{count} errors prohibited this %{resource} from being saved:"
        },
        "odd": "must be odd",
        "other_than": "must be other than %{count}",
        "password_too_long": "is too long",
        "present": "must be blank",
        "required": "must exist",
        "taken": "has already been taken",
        "too_long": {
          "one": "is too long (maximum is 1 character)",
          "other": "is too long (maximum is %{count} characters)"
        },
        "too_short": {
          "one": "is too short (minimum is 1 character)",
          "other": "is too short (minimum is %{count} characters)"
        },
        "wrong_length": {
          "one": "is the wrong length (should be 1 character)",
          "other": "is the wrong length (should be %{count} characters)"
        }
      },
      "unacceptable_request": "A supported version is expected in the Accept header.\n",
      "unavailable_session": "Session %{id} is no longer available in memory.\n\nIf you happen to run on a multi-process server (like Unicorn or Puma) the process\nthis request hit doesn't store %{id} in memory. Consider turning the number of\nprocesses/workers to one (1) or using a different server in development.\n"
    },
    "flash": {
      "actions": {
        "create": {
          "notice": "%{resource_name} was successfully created."
        },
        "destroy": {
          "alert": "%{resource_name} could not be destroyed.",
          "notice": "%{resource_name} was successfully destroyed."
        },
        "update": {
          "notice": "%{resource_name} was successfully updated."
        }
      }
    },
    "footer": {
      "company": {
        "address": "111 True Digital Park West, Unicorn Building, 10th floor, Unit no. 1015, 1018 Sukhumvit Road, Bangchak, Phra Khanong, Bangkok 10260",
        "name": "EVP Corporation Co., Ltd."
      },
      "help_center": {
        "email": "E-mail",
        "header": "Help Center",
        "line": "LINE@",
        "live_chat": "Live chat",
        "open_time": "Monday - Sunday 10:00-18:00"
      },
      "links": {
        "buyer_terms": "Terms of Service (Buyer)",
        "cookies": "Cookie",
        "privacy_policy": "Privacy Policy",
        "security": "Security",
        "seller_terms": "Terms of Service (Seller)"
      }
    },
    "helpers": {
      "form": {
        "account_name": "Account Name",
        "account_number": "Account Number",
        "bank_book_image": "Bank Book Image",
        "brand": "Bank",
        "choose_bank": "Choose your bank",
        "choose_currency": "Choose Currency",
        "choose_your_country": "Choose your country",
        "confirm_action": "Updating account needs re-verification and will take time around 24 hours, are you sure?",
        "confirm_btn": "Update my bank account",
        "name": "Name",
        "tax_id": "Tax ID",
        "verification_text": "Bank account verification typically concludes within 24 hours"
      },
      "label": {
        "bank_account": {
          "account_name": "Account Name",
          "account_number": "Account Number",
          "brand": "Bank",
          "name": "Name",
          "tax_id": "Tax ID"
        }
      },
      "select": {
        "prompt": "Please select"
      },
      "submit": {
        "create": "Create %{model}",
        "submit": "Save %{model}",
        "update": "Update %{model}"
      }
    },
    "http_status": {
      "404": {
        "message": "The page you are looking for might have been removed. <br /> Please try searching for it from the menu.",
        "title": "Page not found"
      },
      "422": {
        "message": "Maybe you tried to change something you didn't have access to. Please try again.",
        "title": "The change you wanted was rejected."
      },
      "500": {
        "message": "The server encountered an internal error and was unable to complete your request.",
        "title": "We're sorry, but something went wrong."
      }
    },
    "mailer": {
      "seller_mailer": {
        "adhoc_wpbkk_notice_max_price": {
          "dear": "Dear %{name},",
          "subject": "Important Update on %{market_name} Price Caps"
        },
        "bank_account_approved_email": {
          "subject": "Your bank account has been approved - %{market_name}"
        },
        "bank_account_rejected_email": {
          "subject": "Your bank account has been rejected - %{market_name}"
        },
        "ticket_approved_email": {
          "subject": "Your wristband resale is approved and now available for sale."
        },
        "ticket_listed_email": {
          "subject": "Your ticket listing is now live! - %{market_name}"
        },
        "ticket_rejected_email": {
          "subject": "Your wristband resale has been rejected."
        },
        "ticket_sold_email": {
          "subject": "Your ticket has been purchased - %{market_name}"
        },
        "ticket_unlisted_email": {
          "subject": "Your ticket listing has been canceled - %{market_name}"
        },
        "ticket_verifying_email": {
          "subject": "Your wristband resale is waiting for verification."
        },
        "unlist_notice_email": {
          "dear": "Dear %{name},",
          "subject": "%{market_name} De-listing Schedule for Wristband and Ticket Sales"
        },
        "wristband_payout_email": {
          "subject": "Wristband Received and Payment Processed."
        },
        "wristband_sold_email": {
          "subject": "Wristband sold - Shipping required"
        }
      }
    },
    "navbar": {
      "cta": {
        "profile_btn": "Menu",
        "resell_btn": "Sell Tickets",
        "to_pay": "To Pay"
      },
      "dropdown": {
        "admin": {
          "dashboard": "Dashboard",
          "group_title": "Admin"
        },
        "common": {
          "menu": "Menu",
          "profile": "Profile",
          "resell": "Sell Tickets"
        },
        "locale": {
          "en": "🇬🇧 English",
          "th": "🇹🇭 ภาษาไทย",
          "title": "Language"
        }
      }
    },
    "number": {
      "currency": {
        "format": {
          "delimiter": ",",
          "format": "%u%n",
          "negative_format": "-%u%n",
          "precision": 2,
          "separator": ".",
          "significant": false,
          "strip_insignificant_zeros": false,
          "unit": "$"
        }
      },
      "format": {
        "delimiter": ",",
        "precision": 3,
        "round_mode": "default",
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false
      },
      "human": {
        "decimal_units": {
          "format": "%n %u",
          "units": {
            "billion": "Billion",
            "million": "Million",
            "quadrillion": "Quadrillion",
            "thousand": "Thousand",
            "trillion": "Trillion",
            "unit": ""
          }
        },
        "format": {
          "delimiter": "",
          "precision": 3,
          "significant": true,
          "strip_insignificant_zeros": true
        },
        "storage_units": {
          "format": "%n %u",
          "units": {
            "byte": {
              "one": "Byte",
              "other": "Bytes"
            },
            "eb": "EB",
            "gb": "GB",
            "kb": "KB",
            "mb": "MB",
            "pb": "PB",
            "tb": "TB",
            "zb": "ZB"
          }
        }
      },
      "nth": {},
      "percentage": {
        "format": {
          "delimiter": "",
          "format": "%n%"
        }
      },
      "precision": {
        "format": {
          "delimiter": ""
        }
      }
    },
    "object": {
      "event": {
        "event": "Event",
        "one": "1 event",
        "other": "%{count} events",
        "zero": "No events"
      },
      "fee": "Processing Fee",
      "order": {
        "one": "1 order",
        "other": "%{count} orders",
        "status": {
          "canceled": "Canceled",
          "completed": "Completed",
          "pending": "Pending",
          "title": "Order status"
        },
        "zero": "No orders"
      },
      "order_id": "Order ID",
      "order_number": "Order Number",
      "price": "Sale price",
      "ticket": "Ticket",
      "ticket_sales_word": {
        "one": "1 ticket left",
        "other": "%{count} tickets",
        "zero": "No tickets available"
      },
      "ticket_type": "Type",
      "wristband": "Wristband"
    },
    "ransack": {
      "all": "all",
      "and": "and",
      "any": "any",
      "asc": "ascending",
      "attribute": "attribute",
      "combinator": "combinator",
      "condition": "condition",
      "desc": "descending",
      "or": "or",
      "predicate": "predicate",
      "predicates": {
        "blank": "is blank",
        "cont": "contains",
        "cont_all": "contains all",
        "cont_any": "contains any",
        "does_not_match": "doesn't match",
        "does_not_match_all": "doesn't match all",
        "does_not_match_any": "doesn't match any",
        "end": "ends with",
        "end_all": "ends with all",
        "end_any": "ends with any",
        "eq": "equals",
        "eq_all": "equals all",
        "eq_any": "equals any",
        "false": "is false",
        "gt": "greater than",
        "gt_all": "greater than all",
        "gt_any": "greater than any",
        "gteq": "greater than or equal to",
        "gteq_all": "greater than or equal to all",
        "gteq_any": "greater than or equal to any",
        "in": "in",
        "in_all": "in all",
        "in_any": "in any",
        "lt": "less than",
        "lt_all": "less than all",
        "lt_any": "less than any",
        "lteq": "less than or equal to",
        "lteq_all": "less than or equal to all",
        "lteq_any": "less than or equal to any",
        "matches": "matches",
        "matches_all": "matches all",
        "matches_any": "matches any",
        "not_cont": "doesn't contain",
        "not_cont_all": "doesn't contain all",
        "not_cont_any": "doesn't contain any",
        "not_end": "doesn't end with",
        "not_end_all": "doesn't end with all",
        "not_end_any": "doesn't end with any",
        "not_eq": "not equal to",
        "not_eq_all": "not equal to all",
        "not_eq_any": "not equal to any",
        "not_in": "not in",
        "not_in_all": "not in all",
        "not_in_any": "not in any",
        "not_null": "is not null",
        "not_start": "doesn't start with",
        "not_start_all": "doesn't start with all",
        "not_start_any": "doesn't start with any",
        "null": "is null",
        "present": "is present",
        "start": "starts with",
        "start_all": "starts with all",
        "start_any": "starts with any",
        "true": "is true"
      },
      "search": "search",
      "sort": "sort",
      "value": "value"
    },
    "receipt": "Receipt",
    "receipts": "Receipts",
    "routes": {
      "back": "Back",
      "back_to_home": "Back to home"
    },
    "status": {
      "error": "Error",
      "success": "Success",
      "warning": "Warning"
    },
    "support": {
      "array": {
        "last_word_connector": ", and ",
        "two_words_connector": " and ",
        "words_connector": ", "
      }
    },
    "system": {
      "menu": "Menu",
      "sign_in": "Sign In",
      "sign_out": "Sign Out"
    },
    "time": {
      "am": "AM",
      "formats": {
        "date": "%B %d, %Y",
        "default": "%d/%m/%Y",
        "long": "%d %b %Y %H:%M",
        "short": "%b %d",
        "us": "%m/%d/%Y %I:%M %p"
      },
      "minute": "minutes",
      "pm": "PM"
    },
    "unit": {
      "quantity": "Quantity"
    },
    "views": {
      "bank_account": {
        "edit": {
          "international_account": "International Account",
          "select_account_type": "Select account type",
          "thai_account": "Thai Account"
        },
        "show": {
          "feature_disabled_message": "Sorry, we are on Songkran holiday from April 12, 2024 - April 16, 2024 <br />We are unable to approve your bank account during this period. Sorry for the inconvenience"
        }
      },
      "buyers": {
        "completed": {
          "action": "View your tickets",
          "description": "You can view your tickets at Eventpop Wallet",
          "title": "Purchase Completed"
        },
        "history": {
          "card": {
            "price": "Price"
          }
        },
        "payment": {
          "form": {
            "terms": {
              "accept": "I agreed to",
              "label": "terms and condition to buy tickets with %{market_name}"
            }
          }
        },
        "pending": {
          "description": "Please make payment within the time limit",
          "title": "Pending Payment"
        }
      },
      "events": {
        "hero": "%{event_title} Official Resale Market",
        "hero_cta": {
          "buy": "Get Tickets",
          "sell": "Sell your Tickets"
        },
        "hero_sub": {
          "buy": {
            "desc": "Select your ticket and securely pay with credit cards or QR PromptPay to secure your spot in the wave.",
            "title": "Ticket Selection and Purchase"
          },
          "qr": {
            "desc": "Scan your order QR code and grab your wristband – your ticket to the ultimate experience.",
            "title": "QR Code Redemption for Wristband"
          },
          "title": "Embark on your resell journey with ease in just 3 simple steps",
          "wristband": {
            "desc": "Dance into euphoria! Immerse yourself in the beats and let the magic take over.",
            "title": "Savoring the Experience"
          }
        },
        "show": {
          "list": {
            "available_at": "Tickets will be available at %{date}",
            "available_until": "Available until %{date}",
            "header": "Get Tickets",
            "start_form": "Starting from"
          }
        }
      },
      "home": {
        "empty": {
          "actions": {
            "view_other_events": "View other events"
          },
          "message": "The event may not have been created yet, or it may not exist. Please try again.",
          "title": "Event not found"
        }
      },
      "orders": {
        "show": {
          "actions": {
            "add_tracking_info": "Add Wristband tracking info",
            "cancel_order": "Cancel this order",
            "choose_method": "Choose payment method",
            "confirm_cancel": "Are you sure you want to cancel this order?",
            "error_refresh": "Refresh this page",
            "next_step": "Next step",
            "processing": "Processing payment...",
            "update_tracking_info": "Update Wristband tracking info"
          },
          "counter": {
            "timeout": "Order canceled by timeout"
          },
          "headers": "Buy ticket safely with",
          "payment": {
            "loading": {
              "title": "Connecting payment with Omise..."
            },
            "ready": {
              "msg_1": "If you encounter any payment issues",
              "msg_2": "Please refresh this page and try again",
              "note": "Note:"
            }
          },
          "pending_order": {
            "actions": {
              "cancel": "Cancel and get new ticket",
              "continue": "Continue with this order"
            },
            "headers": "You have an incomplete order",
            "message": {
              "1": "You have",
              "2": "left to complete this order.",
              "3": "Would you like to continue?"
            }
          },
          "state": {
            "info": "Ticket information",
            "payment": "Payment"
          },
          "table": {
            "footer": {
              "total": "Summary"
            },
            "headers": {
              "list": "List",
              "price": "Price",
              "quantity": "QTY",
              "total": "Total"
            }
          }
        }
      },
      "profile": {
        "bank_account": {
          "actions": {
            "edit_bank_account": "Edit Bank Account for Payout",
            "setup_bank_account": "Setup Bank Account for Payout"
          },
          "form": {
            "cancel_button": "Cancel",
            "desc": "Set up your bank account to receive money from ticket sales.",
            "inter_bank_account": "International Bank Account",
            "inter_bank_account_hint": "International Bank Account: Use an account outside of Thailand",
            "next_button": "Next",
            "select_account_type": "Please select the type of account you want to verify.",
            "select_account_type_hint": "Are you using a Thai bank account or an International bank account?",
            "thai_bank_account": "Thai Bank Account",
            "thai_bank_account_and_more": "And more",
            "thai_bank_account_hint": "Thai Bank Account: Use a bank account issued in Thailand",
            "thai_bank_account_list": "<li>• Bangkok Bank</li><li>• Kasikorn Bank</li><li>• Siam Commercial Bank</li>",
            "thai_bank_account_such_as": "Such as",
            "title": "Bank Account"
          },
          "page_header": "Your Bank Account",
          "page_subheader": "We are verifying your information. Please allow up to 24 hours for confirmation.",
          "sections": {
            "bank_account_info": "Account information",
            "current_status": "Current status",
            "empty_box": "You haven't added a bank account to receive money yet.",
            "info_box": {
              "account_number": "Account Number",
              "account_type": "Account Type",
              "bank_account_name": "Bank Account Name",
              "bank_book_image": "Bank Book Image",
              "bank_name": "Bank Name",
              "name": "Name"
            },
            "submitted_info": "Your submitted info"
          }
        },
        "history": {
          "buy": "Buy",
          "card": {
            "actions": {
              "unlist_ticket": "Unlist Ticket"
            },
            "net_pay_amount": "Pay amount after deduction",
            "popover": {
              "link_to_bank_account": "Set up your bank account",
              "need_bank_account": "You must set up a payout bank account first.",
              "title": "Payout"
            },
            "sold_at": "Sold at"
          },
          "sell": "Sell"
        },
        "menu": {
          "bank_account": "Bank Account",
          "history": "Transactions",
          "sell_ticket": "Sell Tickets"
        },
        "orders": {
          "desc": "Check the status of recent orders, de-list ticket, and view details of ticket.",
          "title": "Transactions"
        },
        "wristband_delivery": {
          "desc": "Update wristband delivery information, tracking number, and upload images.",
          "title": "Wristband Delivery Info"
        }
      },
      "sellers": {
        "bank_account_not_verified": {
          "call_to_action": "Setup payout bank account",
          "header": "Gear up for ticket-selling success like never before!",
          "paragraph": "Setup and verify your payout bank account now to start your epic journey!",
          "upload": {
            "description": "Jumpstart the journey by uploading your bank account details to receive your ticket sales revenue",
            "title": "Upload your bank account"
          },
          "verified": {
            "description": "With your bank account verified, it's time to start selling those tickets and watching your revenue stream flow!",
            "title": "Start selling your tickets"
          },
          "wait": {
            "description": "Bank account verification typically concludes within 24 hours",
            "title": "Wait for verification"
          }
        },
        "confirmed": {
          "actions": {
            "sell_history": "View all your sales",
            "setup_bank_acc": "Setup payout bank account"
          },
          "header": "Ticket Listed Successfully",
          "subheader": "Your ticket is now on sale"
        },
        "delivery_form": {
          "description": "Delivery photo",
          "label": "Upload Delivery photo or package"
        },
        "pending_wristband": {
          "description": "Once the verification is completed, we will send the approval/rejection status of your wristband listing to your email",
          "title": "Please wait for verification"
        },
        "sell_items": {
          "buttons": {
            "ticket": "Sell Ticket",
            "wristband": "Sell Wristband"
          },
          "header": "Select which you want to sell"
        },
        "sell_ticket": {
          "form": {
            "actions": {
              "back": "Back to edit",
              "confirm": "Sell my ticket",
              "next": "Next"
            },
            "choose_ticket": "Select the ticket",
            "pay_amount_after_deduction": {
              "amount_to_receive": "Amount to be received",
              "fee_breakdown_inter_bank": "International bank accounts have a processing fee at %{fee_percent}.",
              "fee_breakdown_thai_bank": "Thai bank accounts have a processing fee at %{fee_percent}.",
              "header": "Pay after deduction",
              "note_1": "You can change your payout bank account during the ticket sale. Ensure you are aware of the fees associated with your selected bank type before listing your ticket.",
              "note_2": "Please ensure you understand the fee for your selected bank account type before listing your ticket. This cannot be adjusted after the ticket is sold.",
              "note_bank_inter": {
                "desc": "Payments are processed within 21 business days, excluding weekends and public holidays. The processing duration may vary depending on your bank account and the designated currency.",
                "title": "For international bank accounts"
              },
              "note_bank_thai": {
                "desc": "Payments are processed within 7-14 business days, excluding weekends and public holidays. The processing duration depends on your bank account.",
                "title": "For Thai bank accounts"
              }
            },
            "summary": {
              "latest_min_price": "Current selling price",
              "latest_price_range": "Current selling price are between",
              "net_price": "Final Amount",
              "up_to": "up to"
            },
            "terms": {
              "accept": "I agreed to",
              "label": "terms and condition to sell tickets with %{market_name}"
            }
          },
          "form_empty": "You have no tickets to sell",
          "page_header": "Sell your ticket",
          "page_subheader": "Choose the ticket you want to sell and the price you want to list it for. Then, set the price. Happy selling!"
        },
        "sell_wristband": {
          "banner": {
            "error": "You have entered an incorrect Wristband ID too many times. Please contact our support team for assistance.",
            "warning": "You have entered an incorrect Wristband ID more than once. %{attempt} times left."
          },
          "form": {
            "actions": {
              "back": "Back to edit",
              "confirm": "Sell my ticket",
              "next": "Next"
            },
            "rules_checkbox": "I agreed and understood all of the wristband selling instructions",
            "side": {
              "logo": "Logo Side",
              "wristband_id": "Wristband ID Side"
            },
            "summary": {
              "net_price": "Pay amount after deduction: "
            },
            "terms": {
              "accept": "I agreed to",
              "label": "terms and condition to sell tickets with %{market_name}"
            },
            "title": "Enter your wristband information"
          },
          "form_guideline": {
            "guide_1": "Fill in the information of the wristband and selling price, then upload the picture, and wait for verification. Upon successful verification, your wristband will be listed for sale.",
            "guide_2": "Once your wristband(s) are sold, <b>they will not be usable at the event in any case.</b>",
            "guide_3": "In case you list your wristband(s) for resale on official resale platform, you are required to ship the sold wristband(s) to the shipping address below, <b>via EMS by ThaiPost only to receive your payout(s).</b> %{destination_address_html} If you ship your parcel(s) via other couriers and the parcel is returned or is not successfully shipped due to any reasons, we reserves the right not to take any responsibility in any case.",
            "guide_4": "You can ship your sold wristband(s) starting from the day they are sold onwards. It is highly not recommended to ship the parcel(s) on weekends and Thai Public holidays, especially on April 12th-16th, 2025, since they are Company Public Holidays, which can lead to failure in parcel shipping.",
            "guide_5": "Upon receipt of the wristband, we will verify your wristband within 5 business days (excluding Sat-Sun, and Public holidays). After that, payment will be processed to your account.",
            "title": "Instructions for Selling Wristbands:"
          },
          "modal": {
            "how_to_find": {
              "description": "Wristband ID usually located on the center back-side of your wristband. Flip your wristband to see the unique code strip.",
              "title": "How to find your wristband ID"
            },
            "wristband_image_guideline": {
              "action": "Understood",
              "id_card": "Thai National ID card",
              "modal_btn": "Wristband photo guideline",
              "notice": "Wristband ID must be clearly visible",
              "passport": "Passport",
              "section": {
                "foreigner": "For Foreigner",
                "thai": "For Thai or Foreigner with Thai ID Card"
              },
              "side": {
                "logo": "Logo Side",
                "wristband_id": "Wristband ID Side"
              },
              "title": "Wristband photo guideline"
            }
          },
          "page_header": "Sell your wristband",
          "page_subheader": "Enter your wristband ID and price, then upload the picture, and wait for verification."
        }
      },
      "shared": {
        "delivery_courier": {
          "thailand_post": "Thailand Post"
        },
        "supported_banks": {
          "description": "The system supports the payout through listed bank accounts in Thailand only. Please check the list of supported banks before start selling tickets.",
          "title": "Supported Banks"
        }
      },
      "users": {
        "seller": {
          "bank_account": {
            "banner": {
              "header": "Please proceed your bank account setup",
              "info": "Your revenue are secure with us, until you had setup your bank account successfully."
            }
          },
          "title": "Seller"
        }
      }
    }
  },
  "th": {
    "action": {
      "cancel": "ยกเลิก",
      "change": "เปลี่ยน",
      "update": "อัพเดท",
      "upload": "อัปโหลด",
      "uploading": "กำลังอัปโหลด"
    },
    "activerecord": {
      "attributes": {
        "bank_account": {
          "account_type": {
            "default": "บัญชีในประเทศไทย",
            "wise": "บัญชีต่างประเทศ"
          },
          "status": {
            "fallback": "ยังไม่ได้เพิ่มบัญชี",
            "pending": "กำลังตรวจสอบ",
            "rejected": "ปฏิเสธ",
            "title": "สถานะ",
            "verified": "สำเร็จ"
          }
        },
        "order": {
          "completed_at": "คำสั่งซื้อเสร็จสิ้นเมื่อ",
          "created_at": "ทำรายการเมื่อ",
          "payment_method": {
            "ali_pay": "Ali Pay",
            "credit_card": "บัตรเครดิต",
            "mobile_banking_kbank": "KBank (Mobile Banking)",
            "title": "วิธีการชำระเงิน",
            "unknown_payment": "---"
          },
          "status": {
            "canceled": "ยกเลิก",
            "completed": "สำเร็จ",
            "pending": "รอการชำระเงิน",
            "title": "สถานะคำสั่งซื้อ"
          }
        },
        "ticket": {
          "delivery_courier": "บริษัทขนส่ง",
          "delivery_proof_image": "รูปภาพหลักฐานการส่งริสแบนด์",
          "delivery_tracking_code": "หมายเลขติดตามการจัดส่ง",
          "eventpop_order_id": "หมายเลขคำสั่งซื้อ",
          "eventpop_ticket_id": "บัตรที่ต้องการขาย",
          "nfc_images": "รูปภาพริสแบนด์",
          "nfc_uid": "หมายเลขริสแบนด์",
          "payout_status": {
            "paid": "จ่ายเงินแล้ว",
            "pending": "รอการจ่ายเงิน",
            "title": "สถานะการจ่ายเงิน"
          },
          "price": "ราคา",
          "reject_note": "หมายเหตุ",
          "reject_reason": {
            "other": "อื่นๆ",
            "photo_requirements_not_met": "ไม่ตรงตามข้อกำหนดของรูปภาพ",
            "title": "เหตุผลที่ปฏิเสธ",
            "unclear_wristband_photos": "รูปภาพริสแบนด์ไม่ชัดเจน",
            "wristband_id_and_photo_mismatch": "รหัสริสแบนด์และรูปภาพไม่ตรงกัน"
          },
          "status": {
            "listed": "ลงขาย",
            "pending": "รอการตรวจสอบ",
            "pending_wristband": "รอการส่งริสต์แบนด์",
            "rejected": "ปฏิเสธ",
            "sold": "ขายแล้ว",
            "title": "สถานะบัตร",
            "unlisted": "ยกเลิกการขาย",
            "wait_wristband_deliver": "รอการจัดส่งริสต์แบนด์จากผู้ขาย"
          }
        }
      },
      "errors": {
        "bank_account": {
          "header": "บัญชีธนาคารของคุณถูกปฏิเสธด้วยเหตุผลดังต่อไปนี้:"
        },
        "models": {
          "ticket": {
            "attributes": {
              "delivery_proof_image": {
                "invalid_image_type": "อัปโหลดไฟล์ PNG, JPG หรือ JPEG เท่านั้น",
                "required": "กรุณาอัปโหลดรูปภาพหลักฐานการส่งสินค้า"
              },
              "nfc_images": {
                "at_least_two": "คุณต้องอัปโหลดรูปภาพด้านหน้าและด้านหลังของสายรัดข้อมือ",
                "invalid_image_type": "อัปโหลดไฟล์ PNG, JPG หรือ JPEG เท่านั้น"
              },
              "price": {
                "greater_than": "ราคาเริ่มต้นที่: %{min}",
                "greater_than_or_equal_to": "ราคาเริ่มต้นที่: %{min}",
                "less_than": "และราคาไม่เกิน: %{max}",
                "less_than_or_equal_to": "และราคาต้องไม่เกิน: %{max}"
              }
            }
          }
        }
      },
      "models": {
        "bank_account": {
          "one": "1 บัญชี",
          "other": "%{count} บัญชี",
          "zero": "ไม่พบบัญชีธนาคาร"
        },
        "order": {
          "one": "1 คำสั่งซื้อ",
          "other": "%{count} คำสั่งซื้อ",
          "zero": "ไม่มีคำสั่งซื้อ"
        },
        "ticket": {
          "one": "1 ใบ",
          "other": "%{count} ใบ",
          "zero": "ไม่มีบัตร"
        }
      },
      "placeholders": {
        "ticket": {
          "eventpop_order_id": "กรุณาเลือกหมายเลขคำสั่งซื้อ",
          "eventpop_ticket_id": "กรุณาเลือกบัตรที่คุณต้องการจะขาย",
          "nfc_uid": "กรอกหมายเลขริสแบนด์",
          "price": "กรุณาตั้งราคาที่คุณต้องการขายบัตร"
        }
      }
    },
    "api_errors": {
      "omise": {
        "recipient": {
          "account_not_found": "ไม่พบบัญชีธนาคาร",
          "bank_not_found": "ไม่พบธนาคาร",
          "name_mismatch": "ชื่อบัญชีธนาคารไม่ตรงกัน"
        }
      },
      "wise": {
        "create_recipient": "ไม่สามารถยืนยันบัญชีรับเงินได้"
      }
    },
    "common": {
      "and": "และ",
      "or": "หรือ",
      "with": "กับ"
    },
    "date": {
      "abbr_day_names": [
        "อา",
        "จ",
        "อ",
        "พ",
        "พฤ",
        "ศ",
        "ส"
      ],
      "abbr_month_names": [
        null,
        "ม.ค.",
        "ก.พ.",
        "มี.ค.",
        "เม.ย.",
        "พ.ค.",
        "มิ.ย.",
        "ก.ค.",
        "ส.ค.",
        "ก.ย.",
        "ต.ค.",
        "พ.ย.",
        "ธ.ค."
      ],
      "day_names": [
        "อาทิตย์",
        "จันทร์",
        "อังคาร",
        "พุธ",
        "พฤหัสบดี",
        "ศุกร์",
        "เสาร์"
      ],
      "month_names": [
        null,
        "มกราคม",
        "กุมภาพันธ์",
        "มีนาคม",
        "เมษายน",
        "พฤษภาคม",
        "มิถุนายน",
        "กรกฎาคม",
        "สิงหาคม",
        "กันยายน",
        "ตุลาคม",
        "พฤศจิกายน",
        "ธันวาคม"
      ],
      "order": [
        "year",
        "month",
        "day"
      ]
    },
    "devise": {
      "confirmations": {
        "confirmed": "Your email address has been successfully confirmed.",
        "send_instructions": "You will receive an email with instructions for how to confirm your email address in a few minutes.",
        "send_paranoid_instructions": "If your email address exists in our database, you will receive an email with instructions for how to confirm your email address in a few minutes."
      },
      "failure": {
        "already_authenticated": "You are already signed in.",
        "inactive": "Your account is not activated yet.",
        "invalid": "Invalid %{authentication_keys} or password.",
        "last_attempt": "You have one more attempt before your account is locked.",
        "locked": "Your account is locked.",
        "not_found_in_database": "Invalid %{authentication_keys} or password.",
        "timeout": "Your session expired. Please sign in again to continue.",
        "unauthenticated": "You need to sign in or sign up before continuing.",
        "unconfirmed": "You have to confirm your email address before continuing."
      },
      "mailer": {
        "confirmation_instructions": {
          "subject": "Confirmation instructions"
        },
        "email_changed": {
          "subject": "Email Changed"
        },
        "password_change": {
          "subject": "Password Changed"
        },
        "reset_password_instructions": {
          "subject": "Reset password instructions"
        },
        "unlock_instructions": {
          "subject": "Unlock instructions"
        }
      },
      "omniauth_callbacks": {
        "failure": "Could not authenticate you from %{kind} because \"%{reason}\".",
        "success": "Successfully authenticated from %{kind} account."
      },
      "passwords": {
        "no_token": "You can't access this page without coming from a password reset email. If you do come from a password reset email, please make sure you used the full URL provided.",
        "send_instructions": "You will receive an email with instructions on how to reset your password in a few minutes.",
        "send_paranoid_instructions": "If your email address exists in our database, you will receive a password recovery link at your email address in a few minutes.",
        "updated": "Your password has been changed successfully. You are now signed in.",
        "updated_not_active": "Your password has been changed successfully."
      },
      "registrations": {
        "destroyed": "Bye! Your account has been successfully canceled. We hope to see you again soon.",
        "signed_up": "Welcome! You have signed up successfully.",
        "signed_up_but_inactive": "You have signed up successfully. However, we could not sign you in because your account is not yet activated.",
        "signed_up_but_locked": "You have signed up successfully. However, we could not sign you in because your account is locked.",
        "signed_up_but_unconfirmed": "A message with a confirmation link has been sent to your email address. Please follow the link to activate your account.",
        "update_needs_confirmation": "You updated your account successfully, but we need to verify your new email address. Please check your email and follow the confirmation link to confirm your new email address.",
        "updated": "Your account has been updated successfully.",
        "updated_but_not_signed_in": "Your account has been updated successfully, but since your password was changed, you need to sign in again."
      },
      "sessions": {
        "already_signed_out": "Signed out successfully.",
        "signed_in": "Signed in successfully.",
        "signed_out": "Signed out successfully."
      },
      "unlocks": {
        "send_instructions": "You will receive an email with instructions for how to unlock your account in a few minutes.",
        "send_paranoid_instructions": "If your account exists, you will receive an email with instructions for how to unlock it in a few minutes.",
        "unlocked": "Your account has been unlocked successfully. Please sign in to continue."
      }
    },
    "errors": {
      "messages": {
        "already_confirmed": "was already confirmed, please try signing in",
        "confirmation_period_expired": "needs to be confirmed within %{period}, please request a new one",
        "expired": "has expired, please request a new one",
        "not_found": "not found",
        "not_locked": "was not locked",
        "not_saved": {
          "one": "1 error prohibited this %{resource} from being saved:",
          "other": "%{count} errors prohibited this %{resource} from being saved:"
        }
      }
    },
    "footer": {
      "company": {
        "address": "เลขที่ 111 อาคารทรูดิจิทัลพาร์ค เวสต์, อาคารยูนิคอร์น ชั้น 10 ห้องเลขที่ 1015, 1018 ถนนสุขุมวิท แขวงบางจาก เขตพระโขนง กรุงเทพมหานคร 10260",
        "name": "บริษัท อีวีพี คอร์ปอเรชั่น จำกัด"
      },
      "help_center": {
        "email": "อีเมล์",
        "header": "ศูนย์ความช่วยเหลือ",
        "line": "LINE@",
        "live_chat": "แชท",
        "open_time": "ทุกวันจันทร์ - อาทิตย์ 10:00-18:00 น."
      },
      "links": {
        "buyer_terms": "ข้อตกลงการใช้บริการ (ผู้ซื้อ)",
        "cookies": "คุกกี้",
        "privacy_policy": "นโยบายความเป็นส่วนตัว",
        "security": "ความปลอดภัย",
        "seller_terms": "ข้อตกลงการใช้บริการ (ผู้ขาย)"
      }
    },
    "helpers": {
      "form": {
        "account_name": "ชื่อบัญชี",
        "account_number": "เลขที่บัญชี",
        "bank_book_image": "รูปหน้าสมุดบัญชีธนาคาร",
        "brand": "ธนาคาร",
        "choose_bank": "เลือกธนาคาร",
        "choose_currency": "เลือกสกุลเงิน",
        "choose_your_country": "เลือกประเทศของคุณ",
        "confirm_action": "การอัพเดทบัญชีจำเป็นต้องมีการตรวจสอบใหม่ และจะใช้เวลาโดยประมาณ 24 ชั่วโมง คุณแน่ใจใช่หรือไม่?",
        "confirm_btn": "อัพเดทบัญชีธนาคาร",
        "name": "ชื่อ",
        "tax_id": "เลขประจำตัวผู้เสียภาษี",
        "verification_text": "การยืนยันบัญชีธนาคารจะใช้เวลาไม่เกิน 24 ชั่วโมง"
      },
      "label": {
        "bank_account": {
          "account_name": "ชื่อบัญชี",
          "account_number": "เลขที่บัญชี",
          "brand": "ธนาคาร",
          "name": "ชื่อ",
          "tax_id": "เลขประจำตัวผู้เสียภาษี"
        }
      }
    },
    "http_status": {
      "404": {
        "message": "หน้าที่คุณกำลังมองหาอาจถูกลบหรือย้ายไปที่อื่น ค้นหาจากเมนู",
        "title": "ไม่พบหน้าที่คุณค้นหา"
      },
      "422": {
        "message": "คุณอาจไม่มีสิทธิ์ในการเปลี่ยนแปลงข้อมูลนั้น โปรดลองอีกครั้ง",
        "title": "ไม่สามารถดำเนินการต่อได้"
      },
      "500": {
        "message": "เซิร์ฟเวอร์เกิดข้อผิดพลาด และไม่สามารถดำเนินการตามคำขอของคุณ",
        "title": "ขออภัย เกิดข้อผิดพลาด!"
      }
    },
    "mailer": {
      "seller_mailer": {
        "adhoc_wpbkk_notice_max_price": {
          "dear": "Dear %{name},",
          "subject": "Important Update on %{market_name} Price Caps"
        },
        "bank_account_approved_email": {
          "subject": "Your bank account has been approved - %{market_name}"
        },
        "bank_account_rejected_email": {
          "subject": "Your bank account has been rejected - %{market_name}"
        },
        "ticket_approved_email": {
          "subject": "Your wristband resale is approved and now available for sale."
        },
        "ticket_listed_email": {
          "subject": "Your ticket listing is now live! - %{market_name}"
        },
        "ticket_rejected_email": {
          "subject": "Your wristband resale has been rejected."
        },
        "ticket_sold_email": {
          "subject": "Your ticket has been purchased - %{market_name}"
        },
        "ticket_unlisted_email": {
          "subject": "Your ticket listing has been canceled - %{market_name}"
        },
        "ticket_verifying_email": {
          "subject": "Your wristband resale is waiting for verification."
        },
        "unlist_notice_email": {
          "dear": "เรียน %{name},",
          "subject": "กำหนดการหยุดการขายสำหรับริสแบนด์และบัตรใน %{market_name}"
        },
        "wristband_payout_email": {
          "subject": "Wristband Received and Payment Processed."
        },
        "wristband_sold_email": {
          "subject": "Wristband sold - Shipping required"
        }
      }
    },
    "navbar": {
      "cta": {
        "profile_btn": "เมนู",
        "resell_btn": "ขายบัตร",
        "to_pay": "รายการที่ค้าง"
      },
      "dropdown": {
        "admin": {
          "dashboard": "Dashboard",
          "group_title": "Admin"
        },
        "common": {
          "menu": "เมนู",
          "profile": "บัญชีผู้ใช้",
          "resell": "ขายบัตร"
        },
        "locale": {
          "en": "🇬🇧 English",
          "th": "🇹🇭 ภาษาไทย",
          "title": "ภาษา"
        }
      }
    },
    "number": {
      "currency": {
        "format": {
          "delimiter": ",",
          "separator": "."
        }
      }
    },
    "object": {
      "event": {
        "event": "งาน",
        "one": "1 งาน",
        "other": "%{count} งาน",
        "zero": "ไม่มีอีเว้นท์"
      },
      "fee": "ค่าดำเนินการ",
      "order": {
        "one": "1 คำสั่งซื้อ",
        "other": "%{count} คำสั่งซื้อ",
        "status": {
          "canceled": "ยกเลิกแล้ว",
          "completed": "เสร็จสิ้น",
          "pending": "รอการชำระเงิน",
          "title": "สถานะคำสั่งซื้อ"
        },
        "zero": "ไม่มีคำสั่งซื้อ"
      },
      "order_id": "รหัสคำสั่งซื้อ",
      "order_number": "หมายเลขคำสั่งซื้อ",
      "price": "ราคาขาย",
      "ticket": "บัตร",
      "ticket_sales_word": {
        "one": "เหลือเพียง 1 ใบสุดท้าย",
        "other": "%{count} ใบ",
        "zero": "บัตรขายหมดแล้ว"
      },
      "ticket_type": "ประเภทบัตร",
      "wristband": "ริสแบนด์"
    },
    "receipt": "ใบเสร็จ",
    "receipts": "ใบเสร็จ",
    "routes": {
      "back": "กลับ",
      "back_to_home": "กลับไปหน้าหลัก"
    },
    "status": {
      "error": "เกิดข้อผิดพลาด",
      "success": "สำเร็จ",
      "warning": "โปรดทราบ"
    },
    "system": {
      "menu": "เมนู",
      "sign_in": "เข้าสู่ระบบ",
      "sign_out": "ออกจากระบบ"
    },
    "time": {
      "formats": {
        "date": "%d %B %Y",
        "default": "%d/%m/%Y",
        "long": "%d %b %Y เวลา %H:%M น.",
        "short": "%d %b"
      },
      "minute": "นาที"
    },
    "unit": {
      "quantity": "จำนวน"
    },
    "views": {
      "bank_account": {
        "edit": {
          "international_account": "บัญชีต่างประเทศ",
          "select_account_type": "เลือกประเภทบัญชี",
          "thai_account": "บัญชีในประเทศไทย"
        },
        "show": {
          "feature_disabled_message": "เนื่องจากเป็นช่วงวันหยุดเทศกาลสงกรานต์ตั้งแต่ 12 - 16 เมษายน พ.ศ. 2567 <br />ทางเราไม่สามารถอนุมัติบัญชีธนาคารของท่านได้ในช่วงเวลานี้ ขออภัยในความไม่สะดวก"
        }
      },
      "buyers": {
        "completed": {
          "action": "ดูบัตรของคุณ",
          "description": "ดูบัตรของคุณได้ที่ Eventpop Wallet",
          "title": "คำสั่งซื้อเสร็จสิ้น"
        },
        "history": {
          "card": {
            "price": "ราคา"
          }
        },
        "payment": {
          "form": {
            "terms": {
              "accept": "ฉันยอมรับ",
              "label": "เงื่อนไขและข้อตกลงในการซื้อบัตรกับ %{market_name}"
            }
          }
        },
        "pending": {
          "description": "โปรดชำระเงินในเวลาที่กำหนด",
          "title": "รอการชำระเงิน"
        }
      },
      "events": {
        "hero": "แพลตฟอร์มซื้อขายบัตรมือสองอย่างเป็นทางการของ %{event_title}",
        "hero_cta": {
          "buy": "ซื้อบัตรมือสอง",
          "sell": "ลงประกาศขายบัตร"
        },
        "hero_sub": {
          "buy": {
            "desc": "เลือกบัตรของคุณและชำระเงินอย่างปลอดภัยด้วยบัตรเครดิตหรือ QR PromptPay",
            "title": "เลือกและซื้อบัตร"
          },
          "qr": {
            "desc": "สแกนรหัส QR ของคำสั่งซื้อของคุณและรับริสแบนด์ แค่นี้ก็พร้อมเข้างานแล้ว",
            "title": "นำ QR ไปรับริสแบนด์ที่หน้างาน"
          },
          "title": "ซื้อบัตรของคุณง่ายๆ เพียง 3 ขั้นตอน",
          "wristband": {
            "desc": "ดื่มด่ำไปกับจังหวะและสนุกกับประสบการณ์ให้เต็มที่!",
            "title": "เพลิดเพลินกับประสบการณ์"
          }
        },
        "show": {
          "list": {
            "available_at": "บัตรจะเปิดขายในวันที่ %{date}",
            "available_until": "จำหน่ายจนถึงวันที่ %{date}",
            "header": "ซื้อบัตร",
            "start_form": "ราคาเริ่มต้น"
          }
        }
      },
      "home": {
        "empty": {
          "actions": {
            "view_other_events": "ดูอีเวนต์อื่นๆ"
          },
          "message": "อีเวนต์อาจยังไม่ถูกสร้างหรือค้นหาไม่พบ โปรดลองอีกครั้ง",
          "title": "ไม่พบอีเวนต์"
        }
      },
      "orders": {
        "show": {
          "actions": {
            "add_tracking_info": "เพิ่มข้อมูลการจัดส่งริสแบนด์",
            "cancel_order": "ยกเลิกคำสั่งซื้อ",
            "choose_method": "เลือกวิธีการชำระเงิน",
            "confirm_cancel": "คุณแน่ใจว่าต้องการยกเลิกคำสั่งซื้อนี้หรือไม่?",
            "error_refresh": "รีเฟรชหน้านี้",
            "next_step": "ขั้นตอนถัดไป",
            "processing": "กำลังดำเนินการ...",
            "update_tracking_info": "อัพเดทข้อมูลการจัดส่งริสแบนด์"
          },
          "counter": {
            "timeout": "คำสั่งซื้อหมดเวลา ยกเลิกโดยอัตโนมัติ"
          },
          "headers": "เลือกซื้อบัตรปลอดภัยกับ",
          "payment": {
            "loading": {
              "title": "กำลังเชื่อมต่อการชำระเงินผ่าน Omise..."
            },
            "ready": {
              "msg_1": "หากพบปัญหาในการจ่ายเงิน",
              "msg_2": "กรุณารีเฟรชหน้านี้เพื่อลองใหม่อีกครั้ง",
              "note": "หมายเหตุ:"
            }
          },
          "pending_order": {
            "actions": {
              "cancel": "ยกเลิกและเลือกบัตรใหม่",
              "continue": "ทำรายการที่ค้างไว้ต่อ"
            },
            "headers": "คุณมีรายการที่ทำค้างไว้",
            "message": {
              "1": "คุณเหลือเวลา",
              "2": "ในการทำรายการสั่งซื้อนี้",
              "3": "คุณต้องการทำรายการต่อหรือไม่?"
            }
          },
          "state": {
            "info": "ข้อมูลบัตรที่ต้องการซื้อ",
            "payment": "ชำระเงิน"
          },
          "table": {
            "footer": {
              "total": "รวมทั้งหมด"
            },
            "headers": {
              "list": "รายการ",
              "price": "ราคา",
              "quantity": "จำนวน",
              "total": "รวม"
            }
          }
        }
      },
      "profile": {
        "bank_account": {
          "actions": {
            "edit_bank_account": "แก้ไขบัญชีธนาคาร",
            "setup_bank_account": "ไปเพิ่มบัญชีธนาคารใหม่"
          },
          "form": {
            "cancel_button": "ยกเลิก",
            "desc": "ตั้งค่าบัญชีรับเงินจากการขายบัตรของคุณ",
            "inter_bank_account": "บัญชีธนาคารต่างประเทศ",
            "inter_bank_account_hint": "บัญชีธนาคารต่างประเทศ: ใช้บัญชีที่ออกโดยธนาคารนอกประเทศไทย",
            "next_button": "ต่อไป",
            "select_account_type": "กรุณาเลือกประเภทบัญชีที่คุณต้องการ",
            "select_account_type_hint": "คุณใช้บัญชีธนาคารไทยหรือบัญชีธนาคารต่างประเทศ?",
            "thai_bank_account": "บัญชีธนาคารไทย",
            "thai_bank_account_and_more": "และธนาคารอื่นๆ",
            "thai_bank_account_hint": "บัญชีธนาคารไทย: ใช้บัญชีธนาคารที่ออกในประเทศไทย",
            "thai_bank_account_list": "<li>• ธนาคารกรุงเทพฯ</li><li>• ธนาคารกสิกรไทย</li><li>• ธนาคารไทยพาณิชย์</li>",
            "thai_bank_account_such_as": "เช่น",
            "title": "บัญชีธนาคาร"
          },
          "page_header": "บัญชีธนาคารของคุณ",
          "page_subheader": "เรากำลังตรวจสอบข้อมูลของคุณ โปรดรอการยืนยันภายใน 24 ชั่วโมง",
          "sections": {
            "bank_account_info": "ข้อมูลบัญชีธนาคาร",
            "current_status": "สถานะปัจจุบัน",
            "empty_box": "คุณยังไม่ได้เพิ่มบัญชีธนาคารสำหรับรับเงิน",
            "info_box": {
              "account_number": "เลขที่บัญชี",
              "account_type": "ประเภทบัญชี",
              "bank_account_name": "ชื่อบัญชีธนาคาร",
              "bank_book_image": "รูปภาพสมุดบัญชีธนาคาร",
              "bank_name": "ธนาคาร",
              "name": "ชื่อ-นามสกุล"
            },
            "submitted_info": "ข้อมูลที่คุณส่งมา"
          }
        },
        "history": {
          "buy": "ประวัติการซื้อ",
          "card": {
            "actions": {
              "unlist_ticket": "ยกเลิกการขายบัตร"
            },
            "net_pay_amount": "รายได้หลังหักค่าดำเนินการ",
            "popover": {
              "link_to_bank_account": "ตั้งค่าบัญชีรับเงิน",
              "need_bank_account": "คุณต้องตั้งค่าบัญชีรับเงินก่อน",
              "title": "การรับเงิน"
            },
            "sold_at": "วันที่ขาย"
          },
          "sell": "ประวัติการขาย"
        },
        "menu": {
          "bank_account": "ตั้งค่าบัญชีรับเงิน",
          "history": "ประวัติการทำรายการ",
          "sell_ticket": "ลงประกาศขายบัตร"
        },
        "orders": {
          "desc": "ตรวจสอบสถานะการทำรายการล่าสุด ยกเลิกการขายบัตร และดูรายละเอียดของบัตร",
          "title": "ประวัติการทำรายการ"
        },
        "wristband_delivery": {
          "desc": "อัพเดทข้อมูลการจัดส่งริสแบนด์ หมายเลขติดตามพัสดุ และอัปโหลดรูปภาพ",
          "title": "ข้อมูลการจัดส่งริสแบนด์"
        }
      },
      "sellers": {
        "bank_account_not_verified": {
          "call_to_action": "ไปตั้งค่าบัญชีรับเงิน",
          "header": "เตรียมพร้อมก่อนลงประกาศขายบัตร",
          "paragraph": "ตั้งค่าและตรวจสอบบัญชีรับเงินเพื่อเริ่มขายบัตรของคุณได้ทันที!",
          "upload": {
            "description": "เริ่มจากอัปโหลดรายละเอียดบัญชีรับเงินเพื่อรับรายได้จากการขายบัตรของคุณ",
            "title": "อัปโหลดบัญชีรับเงิน"
          },
          "verified": {
            "description": "เมื่อบัญชีรับเงินของคุณได้รับการตรวจสอบแล้ว คุณสามารถเริ่มขายบัตรและรับรายได้ได้ทันที",
            "title": "เริ่มขายบัตรของคุณ"
          },
          "wait": {
            "description": "การตรวจสอบบัญชีรับเงินจะเสร็จสิ้นภายใน 24 ชั่วโมง",
            "title": "ตรวจสอบและยืนยันบัญชีของคุณอย่างรวดเร็ว"
          }
        },
        "confirmed": {
          "actions": {
            "sell_history": "ดูประกาศทั้งหมดของคุณ",
            "setup_bank_acc": "ตั้งค่าบัญชีรับเงิน"
          },
          "header": "ลงประกาศขายสำเร็จ",
          "subheader": "ประกาศขายบัตรของคุณได้ถูกเพิ่มในรายการแล้ว"
        },
        "delivery_form": {
          "description": "รูปภาพการจัดส่ง",
          "label": "อัปโหลดรูปภาพหลักฐานการจัดส่งหรือรูปถ่ายพัสดุ"
        },
        "pending_wristband": {
          "description": "เมื่อการตรวจสอบเสร็จสิ้น เราจะส่งสถานะการ อนุมัติ / ปฏิเสธ การลงประกาศขายไปยังอีเมลของคุณ",
          "title": "โปรดรอตรวจสอบ"
        },
        "sell_items": {
          "buttons": {
            "ticket": "ขายบัตร",
            "wristband": "ลงขายริสแบนด์"
          },
          "header": "เริ่มขายบัตรของคุณ"
        },
        "sell_ticket": {
          "form": {
            "actions": {
              "back": "กลับไปแก้ไข",
              "confirm": "ลงประกาศขาย",
              "next": "ขั้นตอนถัดไป"
            },
            "choose_ticket": "เลือกบัตรที่ต้องการขาย",
            "pay_amount_after_deduction": {
              "amount_to_receive": "ยอดเงินที่จะได้รับ",
              "fee_breakdown_inter_bank": "บัญชีธนาคารต่างประเทศ มีค่าดำเนินการที่ %{fee_percent}",
              "fee_breakdown_thai_bank": "บัญชีธนาคารไทย มีค่าดำเนินการที่ %{fee_percent}",
              "header": "ยอดเงินหลังหักค่าธรรมเนียม",
              "note_1": "คุณสามารถเปลี่ยนบัญชีรับเงินได้ระหว่าง การขายบัตร โปรดตรวจสอบค่าธรรมเนียม สำหรับ ประเภทบัญชีที่คุณเลือกก่อนการ ลงขายบัตร",
              "note_2": "โปรดตรวจสอบค่าธรรมเนียมสำหรับ ประเภทบัญชีที่คุณเลือกก่อนการลงขายบัตร เนื่องจากไม่สามารถปรับเปลี่ยนได้ หลังจาก บัตรขายแล้ว",
              "note_bank_inter": {
                "desc": "ยอดเงินของคุณจะถูกโอนเข้าบัญชีธนาคารที่ลงทะเบียนไว้ภายใน 21 วันทำการ (ไม่รวมวันหยุดสุดสัปดาห์และวันหยุดนักขัตฤกษ์) ระยะเวลาอาจแตกต่างกันไปตามธนาคารและสกุลเงินที่เลือก",
                "title": "สำหรับบัญชีธนาคารต่างประเทศ"
              },
              "note_bank_thai": {
                "desc": "ยอดเงินของคุณจะถูกโอนเข้าบัญชีธนาคารที่ลงทะเบียนไว้ ภายใน 7-14 วันทำการ (ไม่รวมวันหยุดสุดสัปดาห์และวันหยุดนักขัตฤกษ์) ระยะเวลาการโอนอาจมีการเปลี่ยนแปลงขึ้นอยู่กับการดำเนินการของธนาคาร",
                "title": "สำหรับบัญชีธนาคารไทย"
              }
            },
            "summary": {
              "latest_min_price": "ราคาขายปัจจุบัน",
              "latest_price_range": "ราคาขายปัจจุบันอยู่ระหว่าง",
              "net_price": "ยอดสุทธิ",
              "up_to": "ถึง"
            },
            "terms": {
              "accept": "ฉันยอมรับ",
              "label": "เงื่อนไขและข้อตกลงในการลงประกาศขายบัตรกับ %{market_name}"
            }
          },
          "form_empty": "คุณไม่มีบัตรที่สามารถขายได้",
          "page_header": "เริ่มขายบัตรของคุณ",
          "page_subheader": "เลือกคำสั่งซื้อของคุณและเลือกบัตรที่ต้องการขาย จากนั้นกำหนดราคาขาย เริ่มลงขายกันเลย"
        },
        "sell_wristband": {
          "banner": {
            "error": "คุณใส่หมายเลขริสแบนด์ผิดหลายครั้ง กรุณาติดต่อทีมสนับสนุนของเราเพื่อขอความช่วยเหลือ",
            "warning": "คุณใส่หมายเลขริสแบนด์ผิด กรอกใส่ได้อีกแค่ %{attempt} ครั้งเท่านั้น"
          },
          "form": {
            "actions": {
              "back": "กลับไปแก้ไข",
              "confirm": "ลงประกาศขาย",
              "next": "ขั้นตอนถัดไป"
            },
            "rules_checkbox": "ฉันได้ยอมรับและเข้าใจขั้นตอนการขายริสแบนด์ทั้งหมด",
            "side": {
              "logo": "ด้านโลโก้",
              "wristband_id": "ด้านหมายเลข"
            },
            "summary": {
              "net_price": "รายได้หลังหักค่าดำเนินการ: "
            },
            "terms": {
              "accept": "ฉันยอมรับ",
              "label": "เงื่อนไขและข้อตกลงในการลงประกาศขายบัตรกับ %{market_name}"
            },
            "title": "กรอกข้อมูลริสแบนด์"
          },
          "form_guideline": {
            "guide_1": "กรอกข้อมูลริสแบนด์และราคาขายพร้อมอัปโหลดรูป แล้วรอการตรวจสอบ เมื่อตรวจสอบสำเร็จ ริสแบนด์ของท่านจะถูกลงขาย",
            "guide_2": "ริสแบนด์ของท่านจะ <b>ไม่สามารถใช้เข้าร่วมงานได้ หากมีการจำหน่ายริสแบนด์สำเร็จ</b>",
            "guide_3": "หลังจากริสแบนด์ของท่านถูกจำหน่ายเรียบร้อยแล้ว ท่านจะต้องส่งริสแบนด์ตัวจริงมายังที่อยู่ด้านล่างนี้ <b>ผ่านทางขนส่ง EMS ที่ให้บริการโดย ไปรษณีย์ไทย เท่านั้น </b> %{destination_address_html} หากท่านจัดส่งผ่านขนส่งอื่นๆ และพัสดุถูกตีกลับ / จัดส่งไม่สำเร็จ ทางบริษัทฯ ขอสงวนสิทธิ์ไม่รับผิดชอบทุกกรณี",
            "guide_4": "ท่านสามารถจัดส่งริสแบนด์ได้ตั้งแต่วันที่ริสแบนด์ถูกจำหน่ายเรียบร้อยแล้วเป็นต้นไป ทั้งนี้ไม่แนะนำให้ท่านจัดส่งพัสดุในช่วงวันหยุด เช่น วันเสาร์-อาทิตย์ รวมถึงวันหยุดราชการต่างๆ โดยเฉพาะในช่วงวันหยุดยาว 12-16 เมษายน 2568 เนื่องจากเป็นวันหยุดทำการของบริษัทฯ และอาจส่งผลให้ทางขนส่งไม่สามารถจัดส่งพัสดุได้",
            "guide_5": "เมื่อเราได้รับริสแบนด์ เราจะทำการตรวจสอบริสแบนด์ของท่านภายใน 5 วันทำการ (ไม่รวมวันเสาร์-อาทิตย์ และวันหยุดนักขัตฤกษ์) และเมื่อผ่านการตรวจสอบเรียบร้อย ยอดเงินของท่านจะถูกโอนไปยังบัญชีธนาคารตามระยะเวลาที่กำหนด",
            "title": "ขั้นตอนการขายริสแบนด์:"
          },
          "modal": {
            "how_to_find": {
              "description": "หมายเลขริสแบนด์จะอยู่ที่ตรงกลางด้านในของริสแบนด์ พลิกริสแบนด์ของคุณเพื่อดูหมายเลขริสแบนด์ของคุณ",
              "title": "วิธีการหาหมายเลขริสแบนด์"
            },
            "wristband_image_guideline": {
              "action": "เข้าใจแล้ว",
              "id_card": "บัตรประจำตัวประชาชน",
              "modal_btn": "ตัวอย่างการถ่ายภาพริสแบนด์",
              "notice": "หมายเลขริสแบนด์ต้องเห็นได้ชัดเจน",
              "passport": "หนังสือเดินทาง (Passport)",
              "section": {
                "foreigner": "สำหรับผู้ขายชาวต่างชาติ (Foreigner)",
                "thai": "สำหรับผู้ขายคนไทย หรือ ชาวต่างชาติที่มีบัตรประจำตัวประชาชนไทย"
              },
              "side": {
                "logo": "ด้านโลโก้",
                "wristband_id": "ด้านหมายเลขริสแบนด์"
              },
              "title": "ตัวอย่างการถ่ายภาพริสแบนด์"
            }
          },
          "page_header": "เริ่มขายริสแบนด์ของคุณ",
          "page_subheader": "ใส่หมายเลขริสแบนด์ของคุณและตั้งราคาขาย จากนั้นอัพโหลดรูปภาพและการอนุมัติจากเรา"
        }
      },
      "shared": {
        "delivery_courier": {
          "thailand_post": "ไปรษณีย์ไทย"
        },
        "supported_banks": {
          "description": "ระบบรองรับการถอนเงินผ่านบัญชีธนาคารในประเทศไทยเท่านั้น โปรดตรวจสอบรายชื่อธนาคารที่รองรับก่อนทำการขายบัตร",
          "title": "ธนาคารที่รองรับ"
        }
      },
      "users": {
        "seller": {
          "bank_account": {
            "banner": {
              "header": "โปรดตั้งค่าบัญชีรับเงินให้สำเร็จ",
              "info": "รายได้ของคุณจะถูกเก็บเงินไว้ในบัญชีของเราจนกว่าคุณจะตั้งค่าบัญชีรับเงินของคุณ"
            }
          },
          "title": "Seller"
        }
      }
    }
  }
}